
export const DELETE_A_PICTURE = "DELETE_A_PICTURE"

export const deleteAPicture = (index) => {
    
    return {
        type: DELETE_A_PICTURE, 
        index: index
    }
}
