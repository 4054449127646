import React, {useState} from "react"
import styled from "styled-components"

import Select from "../components/select"

import { useDispatch } from "react-redux"
import { changeFontSize } from "../store/actions/changeFontSize"

const SettingsButton = styled.button`
    visibility: hidden;
    position: absolute;
    right: ${props => (props.isSettingsPanelOpen ? "-30px" : "206px")};
    transform: rotate(${props => (props.isSettingsPanelOpen ? "90deg" : "0deg")});
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border: none;
    background: var(--background-color);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.16);

    transition: right 0.36s ease, transform 0.36s ease;
    will-change: right;
    will-change: transform;

    &:hover {
        -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.21);
        -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.21);
        box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.21);
    }

    &:active {
        background: var(--light-text-color);
    }

    &:focus {
        outline: none;
    }

    svg {
        fill: var(--primary-color);
    }

    @media screen and (max-width: 1152px) {
        visibility: visible;
    }
`

const StyledSettingsPanel = styled.div`
    padding: 0.6rem 2.6rem 2rem 1.6rem;
    background: var(--background-color);
    border-radius: 16px;
    z-index: 3;
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);

    h4 {
        font-weight: 500;
        margin-top: 1.8rem;
    }

    @media screen and (max-width: 1152px) {
        position: absolute;
        right: ${props => (props.isSettingsPanelOpen ? "42px" : "-216px")};
        transition: right 0.36s ease;
        will-change: right;
        webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.16);
        -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.16);
        box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.16);
    }

`

const SettingsPanel = ({ store }) => {
    
    let [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState(false)

    let [currentLanguage, setCurrentLanguage] = useState(store.getState().language)
    let [currentFontSize, setCurrentFontSize] = useState(store.getState().fontSize)
    let [currentEditorStyle, setCurrentEditorStyle] = useState(store.getState().editorStyle)
    let [currentBackgroundStyle, setCurrentBackgroundStyle] = useState(store.getState().backgroundStyle)

    const dispatch = useDispatch()

    const handleFontSizeChange = event => {
        dispatch(changeFontSize(event.target.value))
    }

    store.subscribe(() => {    
        setCurrentLanguage(store.getState().language)
        setCurrentFontSize(store.getState().fontSize)
        setCurrentEditorStyle(store.getState().editorStyle)
        setCurrentBackgroundStyle(store.getState().backgroundStyle)
    }) 

    return(
        <StyledSettingsPanel isSettingsPanelOpen={isSettingsPanelOpen}>
            <h4>Language</h4>
            <Select type="languageSelect" key="languageSelect" currentValue={currentLanguage} width="140px" />
            <h4>Font size</h4>
            <Select type="fontSizeSelect" key="fontSizeSelect" currentValue={currentFontSize} width="72px" readOnly="false" onChange={handleFontSizeChange} />
            <h4>Editor style</h4>
            <Select type="editorStyleSelect" key="editorStyleSelect" currentValue={currentEditorStyle} width="90px" />
            <h4>Background color</h4>
            <Select type="backgroundStyleSelect" key="backgroundStyleSelect" currentValue={currentBackgroundStyle} width="130px" />

            <SettingsButton onClick={ () => {setIsSettingsPanelOpen(!isSettingsPanelOpen)} } isSettingsPanelOpen={isSettingsPanelOpen} >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" hidden={isSettingsPanelOpen ? true : false}>
                    <path d="M17 12.645v-2.289c-1.17-.417-1.907-.533-2.28-1.431-.373-.9.07-1.512.6-2.625l-1.618-1.619c-1.105.525-1.723.974-2.626.6-.9-.374-1.017-1.117-1.431-2.281h-2.29c-.412 1.158-.53 1.907-1.431 2.28h-.001c-.9.374-1.51-.07-2.625-.6l-1.617 1.619c.527 1.11.973 1.724.6 2.625-.375.901-1.123 1.019-2.281 1.431v2.289c1.155.412 1.907.531 2.28 1.431.376.908-.081 1.534-.6 2.625l1.618 1.619c1.107-.525 1.724-.974 2.625-.6h.001c.9.373 1.018 1.118 1.431 2.28h2.289c.412-1.158.53-1.905 1.437-2.282h.001c.894-.372 1.501.071 2.619.602l1.618-1.619c-.525-1.107-.974-1.723-.601-2.625.374-.899 1.126-1.019 2.282-1.43zm-8.5 1.689c-1.564 0-2.833-1.269-2.833-2.834s1.269-2.834 2.833-2.834 2.833 1.269 2.833 2.834-1.269 2.834-2.833 2.834zm15.5 4.205v-1.077c-.55-.196-.897-.251-1.073-.673-.176-.424.033-.711.282-1.236l-.762-.762c-.52.248-.811.458-1.235.283-.424-.175-.479-.525-.674-1.073h-1.076c-.194.545-.25.897-.674 1.073-.424.176-.711-.033-1.235-.283l-.762.762c.248.523.458.812.282 1.236-.176.424-.528.479-1.073.673v1.077c.544.193.897.25 1.073.673.177.427-.038.722-.282 1.236l.762.762c.521-.248.812-.458 1.235-.283.424.175.479.526.674 1.073h1.076c.194-.545.25-.897.676-1.074h.001c.421-.175.706.034 1.232.284l.762-.762c-.247-.521-.458-.812-.282-1.235s.529-.481 1.073-.674zm-4 .794c-.736 0-1.333-.597-1.333-1.333s.597-1.333 1.333-1.333 1.333.597 1.333 1.333-.597 1.333-1.333 1.333z"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" hidden={!isSettingsPanelOpen ? true : false}>
                    <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
                </svg>
            </SettingsButton>
        </StyledSettingsPanel>
    )
    
}



export default SettingsPanel