import { CHANGE_FONT_SIZE } from "../actions/changeFontSize"
import { TAKE_A_PICTURE } from "../actions/takeAPicture"
import { TAKE_A_PICTURE_COMPLETION } from "../actions/takeAPictureCompletion"
import { DELETE_A_PICTURE } from "../actions/deleteAPicture"
import { EDIT_CODE } from "../actions/editCode"
import { EDIT_FILE_NAME } from "../actions/editFileName"
import { EDIT_SETTING } from "../actions/editSetting"

import availableLanguages from "../../data/availableLanguages.json"
import availableBackgroundStyles from "../../data/availableBackgroundStyles.json"
import availableEditorStyles from "../../data/availableEditorStyles.json"
import suggestedFontSizes from "../../data/suggestedFontSizes.json"

let initialLanguage = availableLanguages[4]

const initialState = {
    language: initialLanguage,
    fontSize: suggestedFontSizes[1],
    editorStyle: availableEditorStyles[0],
    backgroundStyle: availableBackgroundStyles[6],
    code: initialLanguage.welcomeCode,
    fileName: "HelloWorld" + "." + initialLanguage.extension,
    takenPictures: [],
    userIsTakingAPicture: false
}

const reducer = ( state=initialState, action ) => {

    switch(action.type) {
        case CHANGE_FONT_SIZE:
            return {
                ...state,
                fontSize: {
                    "value": action.fontSize,
                    "displayName": action.fontSize
                }
            }
        case TAKE_A_PICTURE:
            if (action.userIsTakingAPicture) {
                return { 
                    ...state, 
                    userIsTakingAPicture: true,
                    takenPictures: [
                        ...state.takenPictures, 
                        0 // This is a fake element for taking a place on array 
                    ]
                }
            }
        case TAKE_A_PICTURE_COMPLETION:
            state.takenPictures.pop()
            return { 
                ...state, 
                userIsTakingAPicture: false,
                takenPictures: [
                    ...state.takenPictures, 
                    action.pictureDataUrl
                ]
            }
        case DELETE_A_PICTURE:
            return { 
                ...state, 
                takenPictures: [
                    ...state.takenPictures.slice(0, action.index),
                    ...state.takenPictures.slice(action.index + 1)
                ]
            }
        case EDIT_CODE: 
            return {...state, code: action.code}
        case EDIT_FILE_NAME: 
            return {...state, fileName: action.fileName}
        case EDIT_SETTING:
            if (action.setting==="languageSelect") {
                return {...state, language: action.newValue}
            } else if (action.setting==="fontSizeSelect") {
                return {...state, fontSize: action.newValue}
            } else if (action.setting==="editorStyleSelect") {
                return {...state, editorStyle: action.newValue}
            } else if (action.setting==="backgroundStyleSelect") {
                return {...state, backgroundStyle: action.newValue}
            }
            break
        default: 
            return state
    }
}

export default reducer