import React, {useState} from "react"
import styled from "styled-components"

import SquareButton from"../components/squareButton"

import ShareButtonIconSrc from "../images/icons/share.svg"
import DownloadButtonIconSrc from "../images/icons/download.svg"

import download from "downloadjs"

import * as JSZip from 'jszip';

import { useDispatch } from "react-redux"
import { deleteAPicture } from "../store/actions/deleteAPicture"

// I created PictureDiv and PictureDeleteButton for hovering parent tecnique of styled components.

const PictureDiv = styled.div`
  background: var(--light-text-color);
  position: relative;
  height: 72px;
  width: 72px;
  z-index: 997;
  flex: 0 0 auto;
  display: flex;
  border-radius: 3px;

  margin-top: 8px;

  @media screen and (max-width: 1152px) {
    margin-top: 0;
  }

  & + div {
    margin-left: 12px;
  }

  img {
    height: 100%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.21);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.21);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.21);
    border-radius: 3px;
  } 
`

const PictureDeleteButton = styled.svg`
  visibility: hidden;
  position: absolute;
  top: 6px;
  right: 6px;
  fill: var(--medium-text-color);
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  cursor: pointer;
  opacity: 0.5;

  @media screen and (max-width: 1152px) {
    visibility: visible;
  }

  ${PictureDiv}:hover & {
    visibility: visible;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }
`

export const TakenPicture = ({ pictureSrc, index }) => {

  const dispatch = useDispatch()

  const handleDeleteAPicture = () => {
    dispatch(deleteAPicture(index))
  }

  const TakenPictureImage = () => (pictureSrc === 0) ? <img /> : <img src={pictureSrc} alt="Code snippet" />

  return(
    <PictureDiv>
      <TakenPictureImage />
      <PictureDeleteButton onClick={handleDeleteAPicture} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" >
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"/>
      </PictureDeleteButton>
    </PictureDiv>
  )
}


const RollBox = ({ className, store }) => {

  let [takenPictures, setTakenPictures] = useState(store.getState().takenPictures)

  store.subscribe(() => {    
    setTakenPictures(store.getState().takenPictures)
  })

  const downloadPictures = () => {

    if (takenPictures.length === 1) {
      var arr = takenPictures[0].split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      let blob = new Blob([u8arr], {type:mime});
      download(blob, 'frammento.png')
    } else if (takenPictures.length > 1) {
      let zip = new JSZip()
      takenPictures.forEach(function (value, i) {
        var arr = value.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
          u8arr[n] = bstr.charCodeAt(n);
        }
        let blob = new Blob([u8arr], {type:mime});
        zip.file("frammento" + (i+1) + ".png", blob)
      })
      zip.generateAsync({type:"blob"})
      .then(function(content) {
        download(content, "frammento.zip");
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    }
  }

  return(
    <div className={className}>
      <div className="picturesContainer"> 
        { takenPictures.map( (dataUrl, index) => <TakenPicture pictureSrc={dataUrl} key={Math.random()} index={index}/> ) } 
      </div>

      <div className="buttonsContainer">
          {/* <SquareButton IconSrc={ShareButtonIconSrc} isDisabled={true} /> */}
          <SquareButton IconSrc={DownloadButtonIconSrc} onClick={ () => downloadPictures() } />
      </div>
    </div>
  )

}

const StyledRollBox = styled(RollBox)`

  // Dont touch this zone for scrolling
  width: 620px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  height: 125px;
  display: flex;
  position: relative;
  align-items: center;

  .picturesContainer {
    height: 100%;
    background: var(--background-color);
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex: 1;
    overflow-x: scroll;
    border-radius: 16px;
    scrollbar-color: var(--semi-light-text-color) var(--background-color);

    padding-left: 1.58rem;

    div:last-child::after {
      content: " ";
      padding-right: 100px;
    }

    &::-webkit-scrollbar {
      height: 8px;
      @media screen and (max-width: 1152px) {
        display: none;
      }
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--semi-light-text-color);
      
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--medium-text-color);
    }
  }

  .buttonsContainer {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-right: 1.86rem;
    z-index: 998;

    @media screen and (max-width: 768px) {
      padding-right: 2.86rem;
    }

    button {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  
`

export default StyledRollBox