import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

import CodeEditor from "../components/codeEditor"
import RollBox from "../components/rollBox"
import SettingsPanel from "../components/settingsPanel"

import { Container } from "../components/layoutComponents"
import { Row } from "../components/layoutComponents"
import { Column} from "../components/layoutComponents"

import { createStore } from "redux"
import reducer from "../store/reducers/reducer"

import { Provider } from "react-redux"

import CodeEditorFixed from "../components/codeEditorFixed"

export const store = createStore(reducer)

const IndexPage = () => {

  return (
    <Provider store={store}>
      <Layout>
        <SEO />

        {/* Is here randomly because it's absolute */}
        <CodeEditorFixed store={store} />

        {/* Structure */}
        <Row justifyContent="space-between">

          {/* Leading column */}
          <Column alignItems="flex-end" marginColumn>
            
          </Column>

          {/* Center column - Code editor */}
          <Column>
            <Container>
              <CodeEditor store={store} /> 
            </Container>
          </Column>

          {/* Trailing column - Editor settings */}
          <Column alignItems="flex-start" justifyContent="center" marginColumn>
            <SettingsPanel store={store} />
          </Column>

        </Row>

        <Row justifyContent="center" marginTop="3rem">
            
              <RollBox store={store} />
            
        </Row>

      </Layout>
    </Provider>
  )

}

export default IndexPage
