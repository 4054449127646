import React, {useState} from "react"
import styled, { ThemeProvider } from "styled-components"

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';

import { useDispatch } from "react-redux"

import { takeAPictureCompletion } from '../store/actions/takeAPictureCompletion'

import domToImage from 'dom-to-image';

// Import all languages and all themes for prismjs library
import "../data/languagesRequirements/prismjs"

const CircleButton = styled.div`
    background-color: ${props => props.color};
    width: 12px;
    height: 12px;
    border-radius: 100%;
`

const FileNameField = styled.input`
    color: ${props => props.theme.foreground};;
    font-size: 0.78rem;
    text-align: center;
    background: none;
    border: none; 
`

const WindowBar = ({ currentFileName, className }) => {
    return(
        <div className={className}>
            <div className="windowCircleButtons">
                <CircleButton color="#FF5F57" />
                <CircleButton color="#FFC12F" />
                <CircleButton color="#29CD42" />
            </div>
            <div className="fileName">
                <FileNameField type="text" value={currentFileName} />
            </div>
        </div>
    )
}

const StyledWindowBar = styled(WindowBar)`
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 0.42rem;
    padding-bottom: 0.42rem;

    .windowCircleButtons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;
        display: flex;
        justify-content: space-between;
        div + div {
            margin-left: 0.36rem;
        }
    }
`

const CodeEditorWindow = ({ currentLanguage, currentEditorStyle, currentCode, currentFileName, className }) => {

    const currentSyntaxHighlighting = (currentCode) => {
        switch (currentLanguage.language) {
            case "go": return highlight(currentCode, languages.go)      
            case "javascript": return highlight(currentCode, languages.javascript)
            case "kotlin": return highlight(currentCode, languages.kotlin)
            case "swift": return highlight(currentCode, languages.swift)
            case "typescript": return highlight(currentCode, languages.typescript)
            case "rust": return highlight(currentCode, languages.rust)
            default: return highlight(currentCode)
        }
    }

    return(
        <div className={className}>
            <StyledWindowBar currentFileName={currentFileName}/>
            
            <div className={[currentLanguage.language, currentEditorStyle.style, "CodeEditorTextAreaContainer"].join(' ')}>
                <Editor
                    className="CodeEditorTextArea"
                    value={currentCode}
                    highlight={currentCode => currentSyntaxHighlighting(currentCode)}
                    padding={18}
                />
            </div>
        </div>
    )
    
}

const StyledCodeEditorWindow = styled(CodeEditorWindow)`
    width: 87%;
    height: 87%;
    background: ${props => props.theme.background};
    border-radius: 17px;
    position: relative;
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);

    .CodeEditorTextAreaContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        overflow: hidden; /// Maybe for scrollbar bug
        
        .CodeEditorTextArea {
            line-height: normal;
            font-family: Roboto Mono, monospace;
            font-weight: 500;
            font-size: ${props => props.currentFontSize.value}px;
            word-wrap: break-word;

            pre {
                word-wrap: break-word;
            }
        }
    }

`

// I created this styled components to make themeprovider works only
const CodeEditorWindowContainer = styled.div`
    background: ${props => props.theme.background};
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .codeEditorLogo {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 6.5%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1rem;
        h4 {
            color: ${props => props.theme.foreground};
            font-weight: 700;
            font-size: 1.2rem;
            opacity: 0.33;
            margin: 0;
        }
    }    
`

const CodeEditor = ({ store, className }) => {

    let [currentLanguage, setCurrentLanguage] = useState(store.getState().language)
    let [currentFontSize, setCurrentFontSize] = useState(store.getState().fontSize)
    let [currentEditorStyle, setCurrentEditorStyle] = useState(store.getState().editorStyle)
    let [currentBackgroundStyle, setCurrentBackgroundStyle] = useState(store.getState().backgroundStyle)
    let [currentCode, setCurrentCode] = useState(store.getState().code)
    let [currentFileName, setCurrentFileName] = useState(store.getState().fileName)

    const dispatchTakeAPictureCompletion = useDispatch()

    store.subscribe(() => {    
        if (store.getState().userIsTakingAPicture) {
            setCurrentLanguage(store.getState().language)
            setCurrentFontSize(store.getState().fontSize)
            setCurrentEditorStyle(store.getState().editorStyle)
            setCurrentBackgroundStyle(store.getState().backgroundStyle)
            setCurrentCode(store.getState().code)
            setCurrentFileName(store.getState().fileName)

            const node = document.getElementById('toImageDiv');
            const scale = 1080 / node.offsetWidth;
            
            domToImage.toPng(node, {
                height: node.offsetHeight * scale,
                width: node.offsetWidth * scale,
                style: {
                transform: "scale(" + scale + ")",
                transformOrigin: "top left",
                width: node.offsetWidth + "px",
                height: node.offsetHeight + "px"
                }
            }).then(dataUrl => {
                dispatchTakeAPictureCompletion(takeAPictureCompletion(dataUrl))
            }).catch(error => {
                console.error("oops, something went wrong!", error);
            });

            // domToImage.toJpeg(document.getElementById('toImageDiv'))
            // .then(function (dataUrl) {
            //     dispatchTakeAPictureCompletion(takeAPictureCompletion(dataUrl))
            // })
        }
    }) 

    return(
        <div className={className} id="toImageDiv">
            <ThemeProvider theme={currentBackgroundStyle}>
                <CodeEditorWindowContainer>
                    <ThemeProvider theme={currentEditorStyle}>
                        <StyledCodeEditorWindow 
                            currentLanguage={currentLanguage} 
                            currentEditorStyle={currentEditorStyle} 
                            currentFontSize={currentFontSize} 
                            currentCode={currentCode}
                            currentFileName={currentFileName} />
                    </ThemeProvider>
                    <div className="codeEditorLogo">
                        <h4>frammento.co</h4>
                    </div>
                </CodeEditorWindowContainer>
            </ThemeProvider>
        </div>
    )
}

const StyledCodeEditor = styled(CodeEditor)`
    width: 620px;
    height: 620px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: -99999;
`

export default StyledCodeEditor