import React, {useState, useEffect, useRef} from "react"
import styled from "styled-components"

import { useDispatch } from "react-redux"

import { editSetting } from "../store/actions/editSetting"

import availableLanguages from "../data/availableLanguages.json"
import availableBackgroundStyles from "../data/availableBackgroundStyles.json"
import availableEditorStyles from "../data/availableEditorStyles.json"
import suggestedFontSizes from "../data/suggestedFontSizes.json"

const SelectBox = styled.input`
    padding-left: 0.8rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: var(--medium-text-color);
    background: var(--secondary-color);
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: ${props => (props.readOnly ? "pointer" : "text")};
    
    &:focus {
        outline: none;
    }
`;

const DropDown = styled.div`
    position: absolute;
    top: 48px;
    border-radius: 8px;
    max-height: 182px;
    overflow-y: auto;
    background: var(--background-color);
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    z-index: 999;
    opacity: ${props => (props.opacity ? props.opacity : "initial")};
    visibility: ${props => (props.visibility? props.visibility : "initial")};
    transition: opacity 0.1s;
    -webkit-transition: opacity 0.1s;

    scrollbar-color: var(--semi-light-text-color) var(--background-color);

    &::-webkit-scrollbar {
      width: 8px;
      @media screen and (max-width: 1152px) {
        display: none;
      }
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--semi-light-text-color);
      border-radius: 30px;
    }

`

const BackgroundColorDropdown = styled.div`
    position: absolute;
    top: 48px;
    border-radius: 8px;
    overflow-y: auto;
    background: var(--background-color);
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.08);
    z-index: 999;
    opacity: ${props => (props.opacity ? props.opacity : "initial")};
    visibility: ${props => (props.visibility? props.visibility : "initial")};
    transition: opacity 0.1s;
    -webkit-transition: opacity 0.1s;

    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(3,1fr);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
`

const StyledBackgroundColorDropDownItem = styled.button`
    width: 30px;
    height: 30px;
    background: ${props => (props.color)};
    cursor: pointer;
    border: none;
    border-radius: 10px;
    transition: box-shadow 0.2s;
    
    -webkit-box-shadow: ${props => (props.isDropDownItemSelected ? "0 0 0 2px #ffaf8f" : "initial")};
    -moz-box-shadow: ${props => (props.isDropDownItemSelected ? "0 0 0 2px #ffaf8f" : "initial")};
    box-shadow: ${props => (props.isDropDownItemSelected ? "0 0 0 2px #ffaf8f" : "initial")};

    &:hover {
        -webkit-box-shadow: 0 0 0 2px #ffaf8f;
        -moz-box-shadow: 0 0 0 2px #ffaf8f;
        box-shadow: 0 0 0 2px #ffaf8f;
    }

    &:active {
    }

    &:focus {
        outline: none;
    }
`

const DropDownItem = ({ handleEditSetting, className, DropDownItem }) => {
    return(
        <div className={className} onClick={() => handleEditSetting()} onKeyDown={() => handleEditSetting()}>
            <span> {DropDownItem.displayName} </span>
            <svg className="svg-icon" viewBox="0 0 20 20">
                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"></path>
            </svg>
        </div>
    )
}

const StyledDropDownItem = styled(DropDownItem)`
    cursor: pointer;
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
    transition: background 0.2s;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;

    & + div {
        border-top: solid 1px var(--light-text-color);
        border-radius: 0;
    }

    &:hover {
        background: var(--alt-color);
    }

    &:active {
        background: var(--dark-alt-color);
    }

    &:last-child {
        border-radius: 0 0 8px 8px;
    }

    & > svg {
        opacity: ${props => (props.isDropDownItemSelected ? "1" : "0")};
        height: 20px;
        fill: var(--primary-color);
        margin-left: 0.8rem;
    }
`

const Select = ({ className, currentValue, readOnly, onChange, type }) => {

    let [isDropDownOpen, setIsDropDownOpen] = useState(false)
    let DropDownItems = []

    const dispatch = useDispatch()

    /// Handle settings change
    const handleEditSetting = (DropDownItem, parentSelect) => {
        dispatch(editSetting(DropDownItem, parentSelect))
    }

    /// Func for close dropdowns on out click
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsDropDownOpen(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)

    if (type==="languageSelect") {
        DropDownItems = availableLanguages.map((item) => <StyledDropDownItem handleEditSetting={() => handleEditSetting(item, type)} DropDownItem={item} key={Math.random()} isDropDownItemSelected={item === currentValue ? true : false} /> )
    } else if (type==="fontSizeSelect") {
        DropDownItems = suggestedFontSizes.map((item) => <StyledDropDownItem handleEditSetting={() => handleEditSetting(item, type)} DropDownItem={item} key={Math.random()} isDropDownItemSelected={item === currentValue ? true : false} /> )
    } else if (type==="editorStyleSelect") {
        DropDownItems = availableEditorStyles.map((item) => <StyledDropDownItem handleEditSetting={() => handleEditSetting(item, type)} DropDownItem={item} key={Math.random()} isDropDownItemSelected={item === currentValue ? true : false} /> )
    } else if (type==="backgroundStyleSelect") {
        DropDownItems =  availableBackgroundStyles.map((item) => <StyledBackgroundColorDropDownItem color={item.displayColor} onClick={ () => handleEditSetting(item, type)}  isDropDownItemSelected={item === currentValue ? true : false} /> )  
    }
        
    return (
        <div className={ className } onClick={ () => {setIsDropDownOpen(!isDropDownOpen)} } on onKeyDown={() => {setIsDropDownOpen(!isDropDownOpen)}}>
            <SelectBox onChange={onChange} value={currentValue.displayName} type="text" readOnly={readOnly ? false : true} />
            <svg className="svg-icon" viewBox="0 0 20 20">
                <path d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path>
            </svg>
            { 
                type != "backgroundStyleSelect" ? 
                    <DropDown ref={wrapperRef} opacity={!isDropDownOpen ? "0" : "1"} visibility={!isDropDownOpen ? "hidden" : "visible"} >
                        {DropDownItems}
                    </DropDown> 
                : 
                    <BackgroundColorDropdown ref={wrapperRef} opacity={!isDropDownOpen ? "0" : "1"} visibility={!isDropDownOpen ? "hidden" : "visible"} >
                        {DropDownItems}
                    </BackgroundColorDropdown>
            }
        </div>
    )
    
}

const StyledSelect = styled(Select)`
    position: relative;
    display: flex;
    align-items: center;
    width: ${props => (props.width ? props.width : "initial")};
    border-radius: 6px;
    transition: box-shadow 0.2s;

    input {
        width: ${props => (props.width ? props.width : "initial")};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 2rem;
    }

    & > svg {
        position: absolute;
        right: 0.6rem;
        height: 20px;
        transform: rotate(-90deg);
        fill: var(--medium-text-color);
        cursor: pointer;
    }

    div {
        width: ${props => (props.width ? props.width : "initial")};
        overflow-x: hidden;
    }

    &:hover {
        box-shadow: 0 0 0 2px var(--alt-color);
    }
`

export default StyledSelect
