
export const TAKE_A_PICTURE = "TAKE_A_PICTURE"

export const takeAPicture = (userIsTakingAPicture) => {
    
    return {
        type: TAKE_A_PICTURE, 
        userIsTakingAPicture: userIsTakingAPicture
    }
}
