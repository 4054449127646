import React, {useState} from "react"
import styled, { ThemeProvider } from "styled-components"

import SquareButton from"../components/squareButton"
import PhotoIconSrc from "../images/icons/photo.svg"

import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'

import { useDispatch } from "react-redux"

import { takeAPicture } from '../store/actions/takeAPicture'
import { editCode } from "../store/actions/editCode"
import { editFileName } from "../store/actions/editFileName"

// Import all languages and all themes for prismjs library
import "../data/languagesRequirements/prismjs"

const CircleButton = styled.div`
    background-color: ${props => props.color};
    width: 12px;
    height: 12px;
    border-radius: 100%;
    @media screen and (max-width: 768px) {
        width: 1.8vw;
        height: 1.8vw;
    }
`

const FileNameField = styled.input`
    color: ${props => props.theme.foreground};
    background: none;
    border: none;
    border-radius: 4px;
    font-size: 0.78rem;
    cursor: pointer;
    transition: background 0.12s, color 0.12s;
    text-align: center;
    height: 25px;
    width: 250px;
    line-height: 0;

    @media screen and (max-width: 768px) {
        font-size: 1.8525vw; //calculated doing tryies on browser
        height: 3.75vw;
        width: 37.483vw;
    }

    &:hover {
        background: ${props => props.theme.lightForeground};
    }

    &:focus {
        cursor: text;
        outline: none;
        background: ${props => props.theme.foreground};
        color: ${props => props.theme.background};
    }
`

const WindowBar = ({ className, store, currentLanguage }) => {

    const dispatch = useDispatch()

    const handleEditFileName = event => {
        dispatch(editFileName(event.target.value))
    }

    // Select all fileName text field when clicking
    const handleFileNameFocus = event => {
        event.target.setSelectionRange(0, event.target.value.length)
    }

    return(
        <div className={className}>
            <div className="windowCircleButtons">
                <CircleButton color="#FF5F57" />
                <CircleButton color="#FFC12F" />
                <CircleButton color="#29CD42" />
            </div>
            <div className="fileName">
                <FileNameField defaultValue={store.getState().fileName} onChange={handleEditFileName} onClick={handleFileNameFocus} type="text" />
            </div>
        </div>
    )
}

const StyledWindowBar = styled(WindowBar)`
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;

    @media screen and (max-width: 768px) {
        height: 6.297vw;
    }

    .windowCircleButtons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            left: 2.5vw;
        }

        div + div {
            margin-left: 0.36rem;

            @media screen and (max-width: 768px) {
                margin-left: 1vw;
            }

        }
    }

    .fileName {
        display: flex;
    }

`

const CodeEditorWindow = ({ currentLanguage, currentEditorStyle, className, store }) => {

    let [code, setCode] = useState(store.getState().code)
    const dispatch = useDispatch()

    const handleEditCode = (code) => {
        setCode(code) // change value on my state let
        dispatch(editCode(code)) // update the code store value
    }

    const currentSyntaxHighlighting = (code) => {
        switch (currentLanguage.language) {      
            case "go": return highlight(code, languages.go)      
            case "javascript": return highlight(code, languages.javascript)
            case "kotlin": return highlight(code, languages.kotlin)
            case "swift": return highlight(code, languages.swift)
            case "typescript": return highlight(code, languages.typescript)
            case "rust": return highlight(code, languages.rust)
            default: return highlight(code)
        }
    }

    return(
        <div className={className}>
            <StyledWindowBar store={store} currentLanguage={currentLanguage} />

            <div className={[currentLanguage.language, currentEditorStyle.style, "CodeEditorTextAreaContainer"].join(' ')}>
                <Editor
                    className="CodeEditorTextArea"
                    value={code}
                    onValueChange={code => handleEditCode(code)}
                    highlight={code => currentSyntaxHighlighting(code)}
                    padding={18}
                />
            </div>
        </div>
    )
    
}

const StyledCodeEditorWindow = styled(CodeEditorWindow)`
    width: 87%;
    height: 87%;
    background: ${props => props.theme.background};
    will-change: background;
    transition: background 0.12s ease;
    border-radius: 17px;
    position: relative;
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);

    .CodeEditorTextAreaContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .CodeEditorTextArea {
            line-height: normal;
            font-family: Roboto Mono, sans-serif;
            font-weight: 500;
            font-size: ${props => props.currentFontSize.value}px;
            word-wrap: break-word;

            @media screen and (max-width: 768px) {
                font-size: ${props => props.currentFontSize.value * 3.14843 / 21}vw;
            }

            textarea {
                ::selection {
                    background: papayawhip;
                    opacity: 1;
                }
                &:focus {
                    outline: none;
                }
            }

            pre {
                word-wrap: break-word;
            }
        }
    }

    @media screen and (max-width: 768px) {
        border-radius: 2.60736vw;
    }
`

// I created this styled components to make themeprovider works only
const CodeEditorWindowContainer = styled.div`
    background: ${props => props.theme.background};
    will-change: background;
    transition: background 0.12s ease;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius 16px;
    border: dashed 3px ${props => props.theme.lightForeground};

    .codeEditorLogo {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 6.5%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1rem;
        @media screen and (max-width: 768px) {
            padding-right: 2.3774vw; //calculated doing tryies on browser
        }
        h4 {
            color: ${props => props.theme.foreground};
            //transition: color 0.12s ease;
            font-weight: 700;
            font-size: 1.2rem;
            opacity: 0.3;
            margin: 0;
            @media screen and (max-width: 768px) {
                font-size: 2.85vw; //calculated doing tryies on browser
            }
        }
    }       
`

const CodeEditor = ({ className, store }) => {

    let [currentLanguage, setCurrentLanguage] = useState(store.getState().language)
    let [currentFontSize, setCurrentFontSize] = useState(store.getState().fontSize)
    let [currentEditorStyle, setCurrentEditorStyle] = useState(store.getState().editorStyle)
    let [currentBackgroundStyle, setCurrentBackgroundStyle] = useState(store.getState().backgroundStyle)

    let [userIsTakingAPicture, setUserIsTakingAPicture] = useState(store.getState().userIsTakingAPicture)

    store.subscribe(() => {    
        setCurrentLanguage(store.getState().language)
        setCurrentFontSize(store.getState().fontSize)
        setCurrentEditorStyle(store.getState().editorStyle)
        setCurrentBackgroundStyle(store.getState().backgroundStyle)

        setUserIsTakingAPicture(store.getState().userIsTakingAPicture)
    }) 

    const dispatch = useDispatch()

    const handleTakeAPicture = () => {
        dispatch(takeAPicture(true))
    }

    return (
        <div className={className} >
            <ThemeProvider theme={currentBackgroundStyle}>
                <CodeEditorWindowContainer>
                    <ThemeProvider theme={currentEditorStyle}>
                        <StyledCodeEditorWindow currentLanguage={currentLanguage} currentEditorStyle={currentEditorStyle} currentFontSize={currentFontSize} store={store} />
                    </ThemeProvider>
                    <div className="codeEditorLogo">
                        <h4>frammento.co</h4>
                    </div>
                </CodeEditorWindowContainer>
            </ThemeProvider>

            <SquareButton IconSrc={PhotoIconSrc} onClick={ () => handleTakeAPicture() } isDisabled={userIsTakingAPicture} />

        </div>
    )

}
  
const StyledCodeEditor = styled(CodeEditor)`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    //this is for responsive square
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    button {
        position: absolute;
        bottom: -24px; // Change it if you change square button size (for example on mobile screens)
    }
`

  export default StyledCodeEditor