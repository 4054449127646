
export const EDIT_SETTING = "EDIT_SETTING"

export const editSetting = (DropDownItem, parentSelect) => {

    return {
        type: EDIT_SETTING,
        setting: parentSelect,
        newValue: DropDownItem,
    }
}