import React from "react"
import styled from "styled-components"

const StyledSquareButton = styled.button`
    cursor: pointer;
    width: 48px;
    height: 48px;
    border: none;
    background: var(--background-color);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.12);
    transition: background 0.2s, box-shadow 0.12s;
    cursor: ${props => (props.isDisabled ? "default" : "pointer")};

    img {
        opacity: ${props => (props.isDisabled ? "0.42" : "initial")};
    }

    &:hover {
        -webkit-box-shadow: ${props => (props.isDisabled ? "0px 0px 23px 0px rgba(0,0,0,0.12)" : "0px 0px 23px 0px rgba(0,0,0,0.16)")};
        -moz-box-shadow: ${props => (props.isDisabled ? "0px 0px 23px 0px rgba(0,0,0,0.12)" : "0px 0px 23px 0px rgba(0,0,0,0.16)")};
        box-shadow: ${props => (props.isDisabled ? "0px 0px 23px 0px rgba(0,0,0,0.12)" : "0px 0px 23px 0px rgba(0,0,0,0.16)")};
    }

    &:active {
        background: ${props => (props.isDisabled ? "var(--background-color)" : "var(--light-text-color)")};
    }

    &:focus {
        outline: none;
    }
`

const SquareButton = ({ IconSrc, onClick, isDisabled }) => (
    <StyledSquareButton onClick={onClick} isDisabled={isDisabled} disabled={isDisabled}>
        <img src={IconSrc} alt="Button icon"/>
    </StyledSquareButton>
)

export default SquareButton